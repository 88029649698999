/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideNavbar from "../../../../web-app-components/desktop/header/SideNavbar";
import MentorYoungStartUp from "../MentorYoungStartUp/MentorYoungStartUp";
import ProfileReviewComponent from "../profileReview/ProfileReviewComponent";
import MentorProfileInputContainer from "./MentorProfileInput/MentorProfileInputContainer";
import { getMentorByUserId } from "../../../../web-app-store/actions/mentorAction";
import isEmpty from "../../../../web-app-store/validations/is-empty";
import PageTitle from "../../../../web-app-components/desktop/header/PageTitle";
import { mentorApplyTitle } from "../../../../web-app-components/desktop/header/activeMenu";

/**
 * MentorProfileGeneral Component
 *
 * Overview:
 * The MentorProfileGeneral component is responsible for rendering the general information section of a mentor's profile. It allows the mentor to update their personal details, skills, software expertise, and other relevant information.
 *
 * Details:
 * - The component receives the following props:
 *   - bio: The mentor's biography.
 *   - fileData: The mentor's profile image file data.
 *   - cropImgBlob: The cropped mentor's profile image blob.
 * - The component manages state for various input fields and error messages.
 * - It makes use of Redux to fetch and update mentor data.
 * - The component includes sub-components for rendering input fields and handling user interactions.
 * - The component uses useEffect to fetch mentor data and update the form fields when the component mounts.
 * - The component includes event handlers for updating the mentor's skills, software expertise, language, industry interests, and other details.
 * - The component performs form validation and dispatches actions to update the mentor's profile.
 *
 * Dependencies:
 * - React, useState, useEffect: Used for building the component and managing state.
 * - Redux: Used for fetching and updating mentor data.
 * - validateMentorProfile: A validation function for validating the mentor's profile data.
 * - InputFieldEmailTextPassword: A sub-component for rendering input fields.
 */
const MentorProfileGeneral = ({ bio, fileData, cropImgBlob }) => {
  const [activeProfileStage, setActiveProfileStage] = useState(1);
  const [isPRofileReview, setIsProfileReview] = useState(false);
  const [mentorDetails, setMentorDetails] = useState();
  const dispatch = useDispatch();
  const mentor = useSelector((state) => state.mentor.mentor);

  useEffect(() => {
    dispatch(getMentorByUserId());
  }, []);
  useEffect(() => {
    if (!isEmpty(mentor)) {
      setMentorDetails(mentor?.[0]);
      if (mentor?.[0].live_profile === "Active Profile") {
        setActiveProfileStage(2);
      }
    }
  }, [mentor]);

  return (
    <>
      {/* (!isEmpty(mentor) && mentor[0].live_profile === "Active Profile") */}
      <SideNavbar />
      <PageTitle
        imgPath={mentorApplyTitle.icon}
        pageTitle={mentorApplyTitle.title}
      />
      <div>
        {activeProfileStage === 2 ? (
          <MentorProfileInputContainer
            {...{
              bio,
              fileData,
              cropImgBlob,
              mentor,
              setIsProfileReview,
              setActiveProfileStage,
            }}
          />
        ) : mentorDetails?.live_profile === "InActive Profile" ||
          isPRofileReview ? (
          <ProfileReviewComponent />
        ) : (
          <MentorYoungStartUp
            {...{ setIsProfileReview, setActiveProfileStage }}
          />
        )}
      </div>
    </>
  );
};

export default MentorProfileGeneral;
