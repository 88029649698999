import React, { useEffect, useState } from "react";
import Select from "react-select";
import BackButton from "../common/BackButton";
import SaveAndNextButton from "../common/SaveAndNextButton";
import { validateInvestorPreferences } from "./../../../web-app-store/validations/invest/investorPreferences";
import {
  getAllIndustries,
  getSocialImpactOptions,
} from "./../../../web-app-store/actions/raiseCapitalAction";
import {
  // updateInvestor,
  createIndustryCategories,
  createInvestor,
  createSocialCategories,
  deleteInvestorCategoryById,
  deleteSocialCategoryById,
  updateInvestor,
} from "../../../web-app-store/actions/investAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import SaveButton from "../common/SaveButton";
import {
  investmentStageOptions,
  revenueStageTypeOptionArray,
} from "../common/allDropdownOptions";
import DropdownSelectCommon from "../common/DropdownSelectCommon";
import DropdownMultiSelectCommon from "../common/DropdownMultiSelectCommon";

// const categoryOptionOneArray = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];
// const categoryOptionTwoArray = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];
// const categoryOptionThreeArray = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];

// const categoryOptionFourArray = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];

// const categoryOptionFiveArray = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];

// const categoryOptionSixArray = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];

// const categoryOptionSevenArray = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];

// const categoryOptionEightArray = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];

/**
 * InvestPreferences Component
 *
 * Overview:
 * The InvestPreferences component is responsible for rendering the investment preferences form for the user. It allows the user to select their preferred social impact categories and industry areas for investment. The component utilizes Redux for state management and makes API calls to retrieve industry and social impact options.
 *
 * Details:
 * - The component uses the useDispatch hook from Redux to dispatch actions for retrieving industry and social impact options.
 * - It uses the useSelector hook from Redux to access the industry and social impact options from the Redux store.
 * - The component uses the useState and useEffect hooks to manage component state and handle side effects.
 * - The form values for social impact categories and industry areas are stored in the component state using the useState hook.
 * - The component renders dropdowns for selecting social impact categories and industry areas.
 * - The selected values from the dropdowns are stored in the component state and can be accessed using the handleChangeSocialImpactCategory and handleChangeIndustryAreaCategory functions.
 * - The component also renders frontendErrors state for displaying any validation errors.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - useDispatch: The component uses the useDispatch hook from Redux.
 * - useSelector: The component uses the useSelector hook from Redux.
 * - getAllIndustries: The component dispatches the getAllIndustries action to retrieve industry options.
 * - getSocialImpactOptions: The component dispatches the getSocialImpactOptions action to retrieve social impact options.
 */
export default function InvestPreferences({
  handleOnSelectTab,
  isEdit,
  setIsEdit,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [values, setValues] = useState({
    socialImpactCategory1: "",
    socialImpactCategory2: "",
    socialImpactCategory3: "",
    socialImpactCategory4: "",
    revenue_stage: "",
    companyInvestmentStage: "",
  });

  const [valuesIndustry, setIndustryValues] = useState({
    industryAreaCategory1: "",
    industryAreaCategory2: "",
    industryAreaCategory3: "",
    industryAreaCategory4: "",
  });

  const [industryOptions, setIndustryOptions] = useState([]);

  const [socialImpactOptions, setSocialImpactOptions] = useState([]);

  const [investorData, setInvestorData] = useState({});
  // const [frontendErrors, setFrontendErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // calling get all industry api

  useEffect(() => {
    dispatch(getAllIndustries());
    dispatch(getSocialImpactOptions());
  }, [dispatch]);

  // set industries oprions to state

  const raiseCapitalIndustries = useSelector(
    (state) => state.raiseCapital.raiseCapitalIndustries
  );

  //social impact options

  const raiseCapitalSocialImpact = useSelector(
    (state) => state.raiseCapital.raiseCapitalSocialImpact
  );

  //SET INVESTOR DATA
  const investor = useSelector((state) => state.invest.investor);

  // INDUSTRY USEEFFECT

  useEffect(() => {
    if (!isEmpty(raiseCapitalIndustries)) {
      let newArray = raiseCapitalIndustries.map((industry) => ({
        value: industry.name,
        label: industry.name,
      }));

      setIndustryOptions(newArray);

      // if (!isEmpty(newArray)) {
      //   setValues({
      //     ...values,
      //     industryAreaCategory1: newArray[0],
      //     industryAreaCategory2: newArray[0],
      //     industryAreaCategory3: newArray[0],
      //     industryAreaCategory4: newArray[0],
      //   });
      // }
    }
  }, [raiseCapitalIndustries]);

  // SOCIAL IMPACT USEEFFECT

  useEffect(() => {
    if (!isEmpty(raiseCapitalSocialImpact)) {
      let newArray = raiseCapitalSocialImpact.map((industry) => ({
        value: industry.name,
        label: industry.name,
      }));

      setSocialImpactOptions(newArray);

      // if (!isEmpty(newArray)) {
      //   setValues({
      //     ...values,

      //     socialImpactCategory1: newArray[0],
      //     socialImpactCategory2: newArray[0],
      //     socialImpactCategory3: newArray[0],
      //     socialImpactCategory4: newArray[0],
      //   });
      // }
    }
  }, [raiseCapitalSocialImpact]);

  // INVESTOR DATA USEEFFECT TO UPDATE INDUSTRIES DROPDOWN DEFAULT OPTIONS
  useEffect(() => {
    if (!isEmpty(investor) && !isEmpty(raiseCapitalIndustries)) {
      let data = investor[0];
      let industryCategories = data.industry_categories;
      setInvestorData(data);

      let similarIndustries = [];
      if (!isEmpty(industryCategories)) {
        industryCategories.forEach((ind) => {
          // console.log(ind);
          raiseCapitalIndustries.forEach((ele) => {
            if (ele.name === ind.ind_category) {
              similarIndustries.push({ id: ind.id, name: ele.name });
            }
          });
        });
      }

      // console.log(similarIndustries);

      // industri values updated
      if (!isEmpty(similarIndustries)) {
        if (similarIndustries.length === 1) {
          setIndustryValues({
            ...valuesIndustry,
            industryAreaCategory1: {
              value: similarIndustries[0].id,
              label: similarIndustries[0].name,
            },
          });
        } else if (similarIndustries.length === 2) {
          setIndustryValues({
            ...valuesIndustry,
            industryAreaCategory1: {
              value: similarIndustries[0].id,
              label: similarIndustries[0].name,
            },
            industryAreaCategory2: {
              value: similarIndustries[1].id,
              label: similarIndustries[1].name,
            },
          });
        } else if (similarIndustries.length === 3) {
          setIndustryValues({
            ...valuesIndustry,
            industryAreaCategory1: {
              value: similarIndustries[0].id,
              label: similarIndustries[0].name,
            },
            industryAreaCategory2: {
              value: similarIndustries[1].id,
              label: similarIndustries[1].name,
            },
            industryAreaCategory3: {
              value: similarIndustries[2].id,
              label: similarIndustries[2].name,
            },
          });
        } else if (similarIndustries.length === 4) {
          setIndustryValues({
            ...valuesIndustry,
            industryAreaCategory1: {
              value: similarIndustries[0].id,
              label: similarIndustries[0].name,
            },
            industryAreaCategory2: {
              value: similarIndustries[1].id,
              label: similarIndustries[1].name,
            },
            industryAreaCategory3: {
              value: similarIndustries[2].id,
              label: similarIndustries[2].name,
            },
            industryAreaCategory4: {
              value: similarIndustries[3].id,
              label: similarIndustries[3].name,
            },
          });
        }
      }
    }
  }, [investor, raiseCapitalIndustries]);

  // INVESTOR DATA USEEFFECT TO UPDATE SOCIAL IMPACT INDUSTRIES DROPDOWN DEFAULT OPTIONS
  useEffect(() => {
    if (!isEmpty(investor) && !isEmpty(raiseCapitalSocialImpact)) {
      let data = investor[0];
      let socialCategories = data.social_categories;
      setInvestorData(data);

      let similarSocialIndustries = [];
      if (!isEmpty(socialCategories)) {
        socialCategories.forEach((ind) => {
          raiseCapitalSocialImpact.forEach((ele) => {
            if (ele.name === ind.soc_category) {
              similarSocialIndustries.push({ id: ind.id, name: ele.name });
            }
          });
        });
      }

      // console.log(similarIndustries);

      // industri values updated

      if (!isEmpty(similarSocialIndustries)) {
        if (similarSocialIndustries.length === 1) {
          setValues({
            ...values,
            socialImpactCategory1: {
              value: similarSocialIndustries[0].id,
              label: similarSocialIndustries[0].name,
            },
          });
        } else if (similarSocialIndustries.length === 2) {
          setValues({
            ...values,
            socialImpactCategory1: {
              value: similarSocialIndustries[0].id,
              label: similarSocialIndustries[0].name,
            },
            socialImpactCategory2: {
              value: similarSocialIndustries[1].id,
              label: similarSocialIndustries[1].name,
            },
          });
        } else if (similarSocialIndustries.length === 3) {
          setValues({
            ...values,
            socialImpactCategory1: {
              value: similarSocialIndustries[0].id,
              label: similarSocialIndustries[0].name,
            },
            socialImpactCategory2: {
              value: similarSocialIndustries[1].id,
              label: similarSocialIndustries[1].name,
            },
            socialImpactCategory3: {
              value: similarSocialIndustries[2].id,
              label: similarSocialIndustries[2].name,
            },
          });
        } else if (similarSocialIndustries.length === 4) {
          setValues({
            ...values,
            socialImpactCategory1: {
              value: similarSocialIndustries[0].id,
              label: similarSocialIndustries[0].name,
            },
            socialImpactCategory2: {
              value: similarSocialIndustries[1].id,
              label: similarSocialIndustries[1].name,
            },
            socialImpactCategory3: {
              value: similarSocialIndustries[2].id,
              label: similarSocialIndustries[2].name,
            },
            socialImpactCategory4: {
              value: similarSocialIndustries[3].id,
              label: similarSocialIndustries[3].name,
            },
          });
        }
      }
    }
  }, [investor, raiseCapitalSocialImpact]);

  useEffect(() => {
    if (!isEmpty(investor)) {
      let data = investor[0];

      // setting revenue stage in revenue stage dropdown
      let revenueStage =
        !isEmpty(data.revenue_stage) && data.revenue_stage.split(" ,");
      let finalRevenueStage = [];
      if (!isEmpty(data.revenue_stage)) {
        revenueStage.forEach((ele) => {
          finalRevenueStage.push({
            value: ele,
            label: ele,
          });
        });
      }

      // setting companyInvestmentStage in revenue stage dropdown
      let companyInvStage =
        !isEmpty(data.company_investment_stage) &&
        data.company_investment_stage.split(" ,");
      let finalCompanyInvStage = [];

      if (!isEmpty(data.company_investment_stage)) {
        companyInvStage.forEach((ele) => {
          finalCompanyInvStage.push({
            value: ele,
            label: ele,
          });
        });
      }

      let valuesObj = {
        revenue_stage: !isEmpty(data.revenue_stage) ? finalRevenueStage : "",
        companyInvestmentStage: !isEmpty(data.company_investment_stage)
          ? finalCompanyInvStage
          : "",
      };
      setValues({ ...values, ...valuesObj });
    }
  }, [investor]);

  const [frontendErrors, setFrontendErrors] = useState({});

  /*============================================================
                           handler
  ============================================================*/
  const handleChangeIndustryAreaCategory1 = (industryAreaCategory1) => {
    setFrontendErrors({});
    setIndustryValues({
      ...valuesIndustry,
      industryAreaCategory1,
    });
  };
  const handleChangeIndustryAreaCategory2 = (industryAreaCategory2) => {
    setFrontendErrors({});
    setIndustryValues({
      ...valuesIndustry,
      industryAreaCategory2,
    });
  };
  const handleChangeIndustryAreaCategory3 = (industryAreaCategory3) => {
    setFrontendErrors({});
    setIndustryValues({
      ...valuesIndustry,
      industryAreaCategory3,
    });
  };
  const handleChangeIndustryAreaCategory4 = (industryAreaCategory4) => {
    setFrontendErrors({});
    setIndustryValues({
      ...valuesIndustry,
      industryAreaCategory4,
    });
  };

  const handleChangeSocialImpactCategory1 = (socialImpactCategory1) => {
    setFrontendErrors({});
    setValues({
      ...values,
      socialImpactCategory1,
    });
  };
  const handleChangeSocialImpactCategory2 = (socialImpactCategory2) => {
    setFrontendErrors({});
    setValues({
      ...values,
      socialImpactCategory2,
    });
  };
  const handleChangeSocialImpactCategory3 = (socialImpactCategory3) => {
    setFrontendErrors({});
    setValues({
      ...values,
      socialImpactCategory3,
    });
  };
  const handleChangeSocialImpactCategory4 = (socialImpactCategory4) => {
    setFrontendErrors({});
    setValues({
      ...values,
      socialImpactCategory4,
    });
  };

  const handleChangerevenue_stage = (revenue_stage) => {
    setFrontendErrors({});
    setValues({
      ...values,
      revenue_stage,
    });
  };

  const handleChangeCompanyInvestmentStage = (companyInvestmentStage) => {
    setFrontendErrors({});
    setValues({
      ...values,
      companyInvestmentStage,
    });
  };

  const callBackStatus = (status) => {
    setLoading(false);
    if (!isEdit) {
      if (status === 200) {
        handleOnSelectTab(2);
      } else {
        handleOnSelectTab(0);
      }
    } else {
      setIsEdit(false);
    }
  };

  const callBackDeleteInvestor = (status) => {
    if (status === 200) {
      let userData = JSON.parse(localStorage.getItem("userData"));
      //GETING ALL SELECTED SOCIAL IMPACT FROM DROPDOWN

      let allSelectedIndustries = [
        !isEmpty(valuesIndustry.industryAreaCategory1) &&
          valuesIndustry.industryAreaCategory1,
        !isEmpty(valuesIndustry.industryAreaCategory2) &&
          valuesIndustry.industryAreaCategory2,
        !isEmpty(valuesIndustry.industryAreaCategory3) &&
          valuesIndustry.industryAreaCategory3,
        !isEmpty(valuesIndustry.industryAreaCategory4) &&
          valuesIndustry.industryAreaCategory4,
      ];

      // console.log(allSelectedIndustries);

      let finalSelectedIndustries = [];

      allSelectedIndustries.forEach((ele) => {
        if (ele !== false) {
          const obj = {
            user: userData.id,
            investor: investorData.id,
            ind_category: ele.label,
          };
          finalSelectedIndustries.push(obj);
        }
      });
      // console.log(finalSelectedIndustries);
      dispatch(
        createIndustryCategories(finalSelectedIndustries, callBackStatus)
      );
    }
  };

  const callBackDeleteSocialImpact = (status) => {
    if (status === 200) {
      let userData = JSON.parse(localStorage.getItem("userData"));
      //GETING ALL SELECTED SOCIAL IMPACT FROM DROPDOWN

      let allSelectedSocialImpact = [
        !isEmpty(values.socialImpactCategory1) && values.socialImpactCategory1,
        !isEmpty(values.socialImpactCategory2) && values.socialImpactCategory2,
        !isEmpty(values.socialImpactCategory3) && values.socialImpactCategory3,
        !isEmpty(values.socialImpactCategory4) && values.socialImpactCategory4,
      ];

      // console.log(allSelectedSocialImpact);

      let finalSelectedSocialImpact = [];

      allSelectedSocialImpact.forEach((ele) => {
        if (ele !== false) {
          const obj = {
            user: userData.id,
            investor: investorData.id,
            soc_category: ele.label,
          };
          finalSelectedSocialImpact.push(obj);
        }
      });

      // console.log(finalSelectedSocialImpact);

      dispatch(
        createSocialCategories(finalSelectedSocialImpact, callBackStatus)
      );
    }
  };

  const handleOnClickSaveAndNext = () => {
    // let formSubmitObj = {
    //   ...values,
    // };

    const { errors, isValid } = validateInvestorPreferences(
      valuesIndustry,
      values
    );

    if (!isValid) {
      setFrontendErrors(errors);
      // console.log(errors);
    } else if (isValid) {
      // console.log("all set");
      setFrontendErrors({});
      setLoading(true);
      let userData = JSON.parse(localStorage.getItem("userData"));
      let finalRevenueStage = [];

      if (!isEmpty(values.revenue_stage)) {
        values.revenue_stage.forEach((ele) => {
          finalRevenueStage.push(ele.value);
        });
      }

      let finalCompanyInvestmentStage = [];

      if (!isEmpty(values.companyInvestmentStage)) {
        values.companyInvestmentStage.forEach((ele) => {
          finalCompanyInvestmentStage.push(ele.value);
        });
      }

      let formData = {
        user: { id: userData.id },
        revenue_stage: finalRevenueStage.join(", "),
        company_investment_stage: finalCompanyInvestmentStage.join(", "),
      };

      if (isEmpty(investor)) {
        dispatch(createInvestor(formData, callBackStatus));
      } else {
        dispatch(updateInvestor(formData, investor[0].id, callBackStatus));
      }

      if (
        isEmpty(investorData.industry_categories) ||
        isEmpty(investorData.social_categories)
      ) {
        // console.log("crate invester tags");

        //GETING ALL SELECTED INDUSTRIES FROM DROPDOWN

        let allSelectedIndustries = [
          !isEmpty(valuesIndustry.industryAreaCategory1) &&
            valuesIndustry.industryAreaCategory1.value,
          !isEmpty(valuesIndustry.industryAreaCategory2) &&
            valuesIndustry.industryAreaCategory2.value,
          !isEmpty(valuesIndustry.industryAreaCategory3) &&
            valuesIndustry.industryAreaCategory3.value,
          !isEmpty(valuesIndustry.industryAreaCategory4) &&
            valuesIndustry.industryAreaCategory4.value,
        ];

        let finalSelectedIndustries = [];

        allSelectedIndustries.forEach((ele) => {
          if (ele !== false) {
            const obj = {
              user: userData.id,
              investor: investorData.id,
              ind_category: ele,
            };
            finalSelectedIndustries.push(obj);
          }
        });

        //GETING ALL SELECTED SOCIAL IMPACT FROM DROPDOWN

        let allSelectedSocialImpact = [
          !isEmpty(values.socialImpactCategory1) &&
            values.socialImpactCategory1.value,
          !isEmpty(values.socialImpactCategory2) &&
            values.socialImpactCategory2.value,
          !isEmpty(values.socialImpactCategory3) &&
            values.socialImpactCategory3.value,
          !isEmpty(values.socialImpactCategory4) &&
            values.socialImpactCategory4.value,
        ];

        let finalSelectedSocialImpact = [];

        allSelectedSocialImpact.forEach((ele) => {
          if (ele !== false) {
            const obj = {
              user: userData.id,
              investor: investorData.id,
              soc_category: ele,
            };
            finalSelectedSocialImpact.push(obj);
          }
        });

        // console.log(allSelectedIndustries);
        // console.log(finalSelectedSocialImpact);

        // let updateData = {
        //   user: { id: userData.id },
        //   industry_categories: finalSelectedIndustries,
        //   social_categories: finalSelectedSocialImpact,
        // };

        // console.log(updateData);

        dispatch(
          createIndustryCategories(finalSelectedIndustries, callBackStatus)
        );
        dispatch(
          createSocialCategories(finalSelectedSocialImpact, callBackStatus)
        );
      } else {
        let industryCategories = investorData.industry_categories;
        let socialCategories = investorData.social_categories;

        // delete insustry category
        if (!isEmpty(industryCategories)) {
          industryCategories.forEach((element, index) => {
            // console.log(element.id);
            // console.log(index);
            dispatch(
              deleteInvestorCategoryById(
                element.id,
                index === 0 && callBackDeleteInvestor
              )
            );
          });
        }

        // delete social category
        if (!isEmpty(socialCategories)) {
          socialCategories.forEach((element, index) => {
            // console.log(element.id);
            dispatch(
              deleteSocialCategoryById(
                element.id,
                index === 0 && callBackDeleteSocialImpact
              )
            );
          });
        }

        // let finalSelectedSocialImpact = [];

        // allSelectedSocialImpact.forEach((ele) => {
        //   if (ele !== false) {
        //     const obj = {
        //       user: userData.id,
        //       investor: investorData.id,
        //       soc_category: ele.label,
        //     };
        //     finalSelectedSocialImpact.push(obj);
        //   }
        // });

        // console.log(finalSelectedSocialImpact);

        // dispatch(
        //   createSocialCategories(finalSelectedSocialImpact, callBackStatus)
        // );

        // console.log(allSelectedSocialImpact);
        // dispatch(updateInvestor(updateData, investorData.id, callBackStatus));
      }
    }
  };

  // console.log(investorData);

  /*============================================================
                           render
  ============================================================*/
  return (
    <div
      className={`invest-personal-tab-panel ${
        isEdit ? "edit_invest_personal_tab_wrap" : ""
      }`}
    >
      <h3 className="invest-preferences-font-24-roboto industry_question_one">
        What industries are you interested in?
      </h3>
      <div className="row mx-0 justify-content-between align-items-start">
        <div className="col-12 col-md-6">
          <div className="vl-form-input industry_input_wrap input_industry_main  vl-form-input--bordered-select-div">
            <label htmlFor="">Industry 1</label>
            <Select
              value={valuesIndustry.industryAreaCategory1}
              onChange={handleChangeIndustryAreaCategory1}
              options={industryOptions}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={valuesIndustry.industryAreaCategory1}
              isSearchable={true}
            />
            {frontendErrors ? (
              <p className="error-message">
                {frontendErrors.industryAreaCategory1}
              </p>
            ) : (
              <p className="error-message opacity-0">error</p>
            )}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="vl-form-input input_industry_main  vl-form-input--bordered-select-div">
            <label htmlFor="">Industry 2</label>
            <Select
              value={valuesIndustry.industryAreaCategory2}
              onChange={handleChangeIndustryAreaCategory2}
              options={industryOptions}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={valuesIndustry.industryAreaCategory2}
              isSearchable={true}
            />
          </div>
        </div>
      </div>
      {/* <div className="row mx-0 justify-content-between align-items-start pt-20">
        <div className="col-12 col-md-6 px-0">
          <div className="vl-form-input  vl-form-input--bordered-select-div">
            <label htmlFor="">Industry 3</label>
            <Select
              value={valuesIndustry.industryAreaCategory3}
              onChange={handleChangeIndustryAreaCategory3}
              options={industryOptions}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={valuesIndustry.industryAreaCategory3}
              isSearchable={true}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 px-0">
          <div className="vl-form-input  vl-form-input--bordered-select-div">
            <label htmlFor="">Industry 4</label>
            <Select
              value={valuesIndustry.industryAreaCategory4}
              onChange={handleChangeIndustryAreaCategory4}
              options={industryOptions}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={valuesIndustry.industryAreaCategory4}
              isSearchable={true}
            />
          </div>
        </div>
      </div> */}
      <h3 className="invest-preferences-font-24-roboto mb-2 m-0 ">
        What social impact areas are you interested in?
      </h3>
      <div className="row mx-0 justify-content-between align-items-start pt-20">
        <div className="col-12 col-md-6">
          <div className="vl-form-input input_industry_main  vl-form-input--bordered-select-div">
            <label htmlFor="">Impact Area 1</label>
            <Select
              value={values.socialImpactCategory1}
              onChange={handleChangeSocialImpactCategory1}
              options={socialImpactOptions}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={values.socialImpactCategory1}
              isSearchable={true}
            />
            {frontendErrors ? (
              <p className="error-message">
                {frontendErrors.socialImpactCategory1}
              </p>
            ) : (
              <p className="error-message opacity-0">error</p>
            )}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="vl-form-input input_industry_main vl-form-input--bordered-select-div">
            <label htmlFor="">Impact Area 2</label>
            <Select
              value={values.socialImpactCategory2}
              onChange={handleChangeSocialImpactCategory2}
              options={socialImpactOptions}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={values.socialImpactCategory2}
              isSearchable={true}
            />
          </div>
        </div>
      </div>
      {/* <div className="row mx-0 justify-content-between align-items-start pt-20">
        <div className="col-12 col-md-6 px-0">
          <div className="vl-form-input  vl-form-input--bordered-select-div">
            <label htmlFor="">Impact Area 3</label>
            <Select
              value={values.socialImpactCategory3}
              onChange={handleChangeSocialImpactCategory3}
              options={socialImpactOptions}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={values.socialImpactCategory3}
              isSearchable={true}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 px-0">
          <div className="vl-form-input  vl-form-input--bordered-select-div">
            <label htmlFor="">Impact Area 4</label>
            <Select
              value={values.socialImpactCategory4}
              onChange={handleChangeSocialImpactCategory4}
              options={socialImpactOptions}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={values.socialImpactCategory4}
              isSearchable={true}
            />
          </div>
        </div>
      </div> */}
      <div className="row mx-0 align-items-center invest-types">
        <div className="col-lg-6 col-12  px-0">
          <h3 className="invest-preferences-font-24-roboto">
            Preferred Revenue Stage?
          </h3>
        </div>
        <div className="col-lg-6 col-12  px-0">
          <DropdownMultiSelectCommon
            isHideLabel={true}
            value={values.revenue_stage}
            onChange={handleChangerevenue_stage}
            options={revenueStageTypeOptionArray}
            placeholder={values.revenue_stage}
            isSearchable={true}
            error={frontendErrors.revenue_stage}
            mainDiv="dropdown_main_div input_industry_main"
          />
        </div>
      </div>
      <div className="row mx-0 align-items-center invest-types">
        <div className="col-lg-6 col-12 px-0">
          <h3 className="invest-preferences-font-24-roboto performance_title">
            Preferred Investment Stage?
          </h3>
        </div>
        <div className="col-lg-6 col-12 px-0 ">
          <DropdownMultiSelectCommon
            isHideLabel={true}
            value={values.companyInvestmentStage}
            onChange={handleChangeCompanyInvestmentStage}
            options={investmentStageOptions}
            placeholder={values.companyInvestmentStage}
            isSearchable={true}
            error={frontendErrors.companyInvestmentStage}
            mainDiv="dropdown_main_div input_industry_main"
          />
        </div>
      </div>
      <div className="d-flex mx-0 align-items-center justify-content-end preferences-save-btn-div pe-0">
        {isEdit ? (
          <SaveButton
            handleOnClickSaveButton={handleOnClickSaveAndNext}
            loading={loading}
          />
        ) : (
          <React.Fragment>
            <BackButton handleOnClickBack={() => handleOnSelectTab(0)} />
            <SaveAndNextButton
              handleOnClickSaveAndNext={handleOnClickSaveAndNext}
              loading={loading}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
